import React, { useState } from "react"
import Profile from "../components/profile"
import SignUp from "../components/signup"
import Products from "../components/products"
import Address from "../components/address"
import LayoutGetStarted from "../components/layoutGetStarted"
import { CartProvider } from "../context/cartContext"
import defaultProfile from "../components/defaultProfile"
import Meta from "../components/meta"
import firebase from "gatsby-plugin-firebase"

export default function GetStartedPage() {
  const [activeScreen, setActiveScreenState] = useState(1)
  const [profile, setProfile] = useState(() => { return defaultProfile() })
  const [postcodes, setPostcodes] = useState([])
  const [discounts, setDiscounts] = useState([])
  const [shippingFees, setShippingFees] = useState([])

  const setActiveScreen = (screen) => {
    if (screen === 2 & firebase.auth().currentUser !== null) {
        firebase
        .functions()
        .httpsCallable('getProfile')().then((result) => {
          if(result?.data?.profile){
            setProfile(result.data.profile)
          }
        });      
      if (activeScreen < screen)
      
        screen = 3
      else
        screen = 1
    }
    setActiveScreenState(screen)
    window["scrollTo"]({ top: 0, behavior: "smooth" })
  }

  const handleDiscountBar = (profile) => {
     setProfile(profile);
  }

  return (
    <CartProvider>
      <Meta title="Get Started" />
      <LayoutGetStarted activeScreen={activeScreen} discounts={discounts} profile={profile} setActiveScreen={setActiveScreen}>
        {(() => {
          switch (activeScreen) {
            case 1: return (<Profile activeScreen={activeScreen} setActiveScreen={setActiveScreen} profile={profile} setProfile={setProfile} wizard={true}  />)
            case 2: return (<SignUp activeScreen={activeScreen} setActiveScreen={setActiveScreen} profile={profile} setProfile={setProfile} type="wizard" handleDiscountBar={handleDiscountBar} />)
            case 3: return (<Products activeScreen={activeScreen} setActiveScreen={setActiveScreen} profile={profile} setProfile={setProfile} setPostcodes={setPostcodes} discounts={discounts} setDiscounts={setDiscounts} shippingFees={shippingFees} setShippingFees={setShippingFees} wizard={true} />)
            case 4: return (<Address activeScreen={activeScreen} setActiveScreen={setActiveScreen} profile={profile} setProfile={setProfile} postcodes={postcodes} discounts={discounts} shippingFees={shippingFees} wizard={true} />)
            default: return (<Profile activeScreen={activeScreen} setActiveScreen={setActiveScreen} profile={profile} setProfile={setProfile} wizard={true} />)
          }
        })()}
      </LayoutGetStarted>
    </CartProvider>
  )
}